import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieConsent from 'react-cookie-consent';

import './App.css';

import Menu from './Component/Menu';
import Footer from './Component/Footer';
import Accesso from './Contenuti/Accesso';
import Home from './Contenuti/Home';
import BustePaga from './Contenuti/BustePaga';
import StatoPulizie from './Contenuti/StatoPulizie';
import Protocolli from './Contenuti/Protocolli';
import ConsultazioneProtocolli from './Contenuti/ConsultazioneProtocolli';
import IngressiUscite from './Contenuti/IngressiUscite';
import ComunicazioniInterne from './Contenuti/ComunicazioniInterne';
import ControlloOrari from './Contenuti/ControlloOrari';
import Ordini from './Contenuti/Ordini';
import Prodotti from './Contenuti/Prodotti';
import Scansioni from './Contenuti/Scansioni';
import Programma from './Contenuti/Programma';
import Sms from './Contenuti/Sms';
import Turni from './Contenuti/Turni';
import Anagrafiche from './Contenuti/Anagrafiche';
import Certificazioni from './Contenuti/Certificazioni';
import Preventivi from './Contenuti/Preventivi';
import ComunicazioniAnonime from './Contenuti/ComunicazioniAnonime';
import Whistleblowing from './Contenuti/Whistleblowing';
import Questionari from './Contenuti/Questionari';
import Rapporti from './Contenuti/Rapporti';
import Piani from './Contenuti/Piani';
import Customer from './Contenuti/Customer';

export function getOut(_X) {
	let LivelloUtenza = parseInt(sessionStorage.getItem('ProUser'));
	if (LivelloUtenza >= _X) {
	} else {
		LogOut();
	}
}

export function LogOut() {
	sessionStorage.setItem('CodiceFiscale', '');
	sessionStorage.setItem('Nome', '');
	sessionStorage.setItem('ProUser', -10);
	sessionStorage.setItem('Azienda', '');
	sessionStorage.setItem('IDutente', '');
	window.location.assign(`${process.env.REACT_APP_URL_BASE}`);
}

export function DaEpochAData(_Data) {
	return new Date(_Data * 1000).toISOString().slice(0, 19);
}

/* export const Validita = function () {
    let LivelloUtenza = parseInt(sessionStorage.getItem('ProUser'));

    if (LivelloUtenza === -10) {
        console.log(LivelloUtenza);
    } else if (LivelloUtenza === -1) {
        console.log(LivelloUtenza);
    } else if (LivelloUtenza === 0) {
        console.log(LivelloUtenza);
    } else if (LivelloUtenza === 2) {
        console.log(LivelloUtenza);
    } else if (LivelloUtenza === 5) {
        console.log(LivelloUtenza);
    } else if (LivelloUtenza === 10) {
        console.log(LivelloUtenza);
    } else if (LivelloUtenza === -11) {
        console.log(LivelloUtenza);
    }
}; */

function App() {
	return (
		<>
			<ToastContainer
				position='top-right'
				autoClose={10000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='colored'
			/>
			<Menu />
			<Routes>
				<Route path='/' element={<Home />} />
				<Route path='/Home' element={<Home />} />
				<Route path='/Accesso' element={<Accesso />} />
				<Route path='/Customer' element={<Customer />} />
				<Route path='/BustePaga' element={<BustePaga />} />
				<Route path='/StatoPulizie' element={<StatoPulizie />} />
				<Route path='/Protocolli' element={<Protocolli />} />
				<Route path='/ConsultazioneProtocolli' element={<ConsultazioneProtocolli />} />
				<Route path='/IngressiUscite' element={<IngressiUscite />} />
				<Route path='/ComunicazioniInterne' element={<ComunicazioniInterne />} />
				<Route path='/ControlloOrari' element={<ControlloOrari />} />
				<Route path='/Ordini' element={<Ordini />} />
				<Route path='/Prodotti' element={<Prodotti />} />
				<Route path='/Scansioni' element={<Scansioni />} />
				<Route path='/Programma' element={<Programma />} />
				<Route path='/Sms' element={<Sms />} />
				<Route path='/Turni' element={<Turni />} />
				<Route path='/Anagrafiche' element={<Anagrafiche />} />
				<Route path='/questionari' element={<Questionari />} />
				<Route path='/Rapporti' element={<Rapporti />} />
				<Route path='/Piani' element={<Piani />} />

				<Route path='/ComunicazioniAnonime' element={<ComunicazioniAnonime />} />
				<Route path='/Whistleblowing' element={<Whistleblowing />} />
				<Route path='/Preventivi' element={<Preventivi />} />
				<Route path='/Certificazioni' element={<Certificazioni />} />

				<Route path='*' element={<Navigate to='/' />} />
			</Routes>
			<Footer />
			<CookieConsent
				enableDeclineButton
				declineButtonText='Rifiuto'
				location='bottom'
				buttonText='OK'
				cookieName='Cookies'
				style={{ background: '#2B373B' }}
				buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
				expires={90}
			>
				Questo sito utilizza cookie tecnici ed altri strumenti tecnici necessari per il suo funzionamento. Nessun dato dell'utente viene
				registrato sui nostri server, tutti gli strumenti in uso sono ad esclusivo appannaggio del corretto funzionamento tecnico del sito
				stesso.
			</CookieConsent>
		</>
	);
}

export default App;
