import React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { LogOut } from '../App';
import './CSS/Menu.css';

import {
	IoHome,
	IoWalletOutline,
	IoCheckmarkCircleOutline,
	IoMedkitSharp,
	IoBandageOutline,
	IoIdCardOutline,
	IoChatboxEllipsesOutline,
	IoStopwatchOutline,
	IoCartOutline,
	IoCubeOutline,
	IoScanCircleOutline,
	IoMailOutline,
	IoPersonOutline,
	IoLogOutOutline,
	IoEnterOutline,
	IoNewspaperOutline,
	IoTrailSignOutline,
	IoWatch,
	IoHandLeftOutline,
} from 'react-icons/io5';
import { GiWhistle } from 'react-icons/gi';

export default function Menu() {
	function Smarmella() {
		document.getElementById('Navigation').classList.toggle('Open');
		const Aperto = document.getElementById('Navigation').classList.contains('Open');

		Aperto ? document.getElementById('Contenuto').classList.add('Open') : document.getElementById('Contenuto').classList.remove('Open');
	}

	function Casa() {
		document.getElementById('Navigation').classList.remove('Open');
		document.getElementById('Contenuto').classList.remove('Open');
	}

	function LinkAttivo({ to, children, ...props }) {
		const percorsoRisolto = useResolvedPath(to);
		const isActive = useMatch({ path: percorsoRisolto.pathname, end: true });
		return (
			<li className={isActive ? 'Active' : ''}>
				<Link to={to} {...props}>
					{children}
				</Link>
			</li>
		);
	}

	let LivelloUtenza = parseInt(sessionStorage.getItem('ProUser'));

	return (
		<div id='Navigation'>
			<div id='MenuToggle' onClick={Smarmella}></div>
			<ul>
				<LinkAttivo to='/Home' style={{ '--color': 'red' }} className='List' onClick={Casa} id='Home'>
					<span className='Icon'>
						<IoHome />
					</span>
					<span className='Text'>Home</span>
				</LinkAttivo>

				<LinkAttivo to='/Whistleblowing' style={{ '--color': 'black' }} className='List' onClick={Casa} id='whistleblowing'>
					<span className='Icon'>
						<GiWhistle />
					</span>
					<span className='Text'>Segn. Illeciti</span>
				</LinkAttivo>

				{LivelloUtenza >= 11 ? (
					<LinkAttivo to='/Customer' className='List' style={{ '--color': 'coral' }} onClick={Casa} id='customer'>
						<span className='Icon'>
							<IoHandLeftOutline />
						</span>
						<span className='Text'>Customer</span>
					</LinkAttivo>
				) : (
					''
				)}

				{LivelloUtenza >= 10 ? (
					<LinkAttivo to='/Anagrafiche' className='List' style={{ '--color': 'gold' }} onClick={Casa} id='anagrafiche'>
						<span className='Icon'>
							<IoPersonOutline />
						</span>
						<span className='Text'>Anagrafiche</span>
					</LinkAttivo>
				) : (
					''
				)}
				{LivelloUtenza >= 0 && LivelloUtenza !== 2 ? (
					<LinkAttivo to='/ComunicazioniInterne' className='List' style={{ '--color': 'olive' }} onClick={Casa} id='ComunicazioniInterne'>
						<span className='Icon'>
							<IoChatboxEllipsesOutline />
						</span>
						<span className='Text'>Com. Interne</span>
					</LinkAttivo>
				) : (
					''
				)}
				{LivelloUtenza >= 0 && LivelloUtenza !== 2 ? (
					<LinkAttivo to='/BustePaga' className='List' style={{ '--color': 'green' }} onClick={Casa} id='BustePaga'>
						<span className='Icon'>
							<IoWalletOutline />
						</span>
						<span className='Text'>Documentazione</span>
					</LinkAttivo>
				) : (
					''
				)}

				{LivelloUtenza >= 0 && LivelloUtenza !== 2 ? (
					<LinkAttivo to='/Protocolli' className='List' style={{ '--color': 'orange' }} onClick={Casa} id='Protocolli'>
						<span className='Icon'>
							<IoMedkitSharp />
						</span>
						<span className='Text'>Prot. Malattia</span>
					</LinkAttivo>
				) : (
					''
				)}
				{LivelloUtenza >= 10 ? (
					<LinkAttivo
						to='/ConsultazioneProtocolli'
						className='List'
						style={{ '--color': 'blue' }}
						onClick={Casa}
						id='ConsultazioneProtocolli'
					>
						<span className='Icon'>
							<IoBandageOutline />
						</span>
						<span className='Text'>Cons. Protocolli</span>
					</LinkAttivo>
				) : (
					''
				)}
				{LivelloUtenza >= 11 && LivelloUtenza !== 2 ? (
					<LinkAttivo to='/IngressiUscite' className='List' style={{ '--color': 'purple' }} onClick={Casa} id='IngressiUscite'>
						<span className='Icon'>
							<IoIdCardOutline />
						</span>
						<span className='Text'>Timbrature</span>
					</LinkAttivo>
				) : (
					''
				)}
				{LivelloUtenza >= 10 ? (
					<LinkAttivo to='/Sms' className='List' style={{ '--color': 'cornflowerblue' }} onClick={Casa} id='Sms'>
						<span className='Icon'>
							<IoMailOutline />
						</span>
						<span className='Text'>SMS</span>
					</LinkAttivo>
				) : (
					''
				)}

				{LivelloUtenza >= -1 && LivelloUtenza !== 2 ? (
					<LinkAttivo to='/Scansioni' className='List' style={{ '--color': 'fuchsia' }} onClick={Casa} id='Scansioni'>
						<span className='Icon'>
							<IoScanCircleOutline />
						</span>
						<span className='Text'>Scansioni</span>
					</LinkAttivo>
				) : (
					''
				)}
				{LivelloUtenza >= 10 ? (
					<LinkAttivo to='/Prodotti' className='List' style={{ '--color': 'silver' }} onClick={Casa} id='Prodotti'>
						<span className='Icon'>
							<IoCubeOutline />
						</span>
						<span className='Text'>Prodotti</span>
					</LinkAttivo>
				) : (
					''
				)}

				{LivelloUtenza >= 10 || LivelloUtenza === 2 ? (
					<LinkAttivo to='/StatoPulizie' className='List' style={{ '--color': 'pink' }} onClick={Casa} id='StatoPulizie'>
						<span className='Icon'>
							<IoCheckmarkCircleOutline />
						</span>
						<span className='Text'>Stato Pulizie</span>
					</LinkAttivo>
				) : (
					''
				)}

				{LivelloUtenza >= 11 ? (
					<LinkAttivo to='/ControlloOrari' className='List' style={{ '--color': 'Navy' }} onClick={Casa} id='ControlloOrari'>
						<span className='Icon'>
							<IoStopwatchOutline />
						</span>
						<span className='Text'>Cons. Orari</span>
					</LinkAttivo>
				) : (
					''
				)}
				{LivelloUtenza >= 5 ? (
					<LinkAttivo to='/Ordini' className='List' style={{ '--color': 'teal' }} onClick={Casa} id='Ordini'>
						<span className='Icon'>
							<IoCartOutline />
						</span>
						<span className='Text'>Ordini</span>
					</LinkAttivo>
				) : (
					''
				)}

				{LivelloUtenza >= 0 && LivelloUtenza !== 2 ? (
					<LinkAttivo to='/Turni' className='List' style={{ '--color': 'goldenrod' }} onClick={Casa} id='Turni'>
						<span className='Icon'>
							<IoWatch />
						</span>
						<span className='Text'>Turni</span>
					</LinkAttivo>
				) : (
					''
				)}

				{LivelloUtenza >= 5 ? (
					<LinkAttivo to='/Rapporti' className='List' style={{ '--color': 'tan' }} onClick={Casa} id='Rapporti'>
						<span className='Icon'>
							<IoNewspaperOutline />
						</span>
						<span className='Text'>Rapporti</span>
					</LinkAttivo>
				) : (
					''
				)}
				{LivelloUtenza >= 10 ? (
					<LinkAttivo to='/Piani' className='List' style={{ '--color': 'indigo' }} onClick={Casa} id='Piani'>
						<span className='Icon'>
							<IoTrailSignOutline />
						</span>
						<span className='Text'>Piani Di Lavoro</span>
					</LinkAttivo>
				) : (
					''
				)}

				{LivelloUtenza >= -1 ? (
					<LinkAttivo to='/#' className='List' style={{ '--color': 'crimson' }} onClick={LogOut} id='LogOut'>
						<span className='Icon'>
							<IoLogOutOutline />
						</span>
						<span className='Text'>Esci</span>
					</LinkAttivo>
				) : (
					<LinkAttivo to='/Accesso' className='List' style={{ '--color': 'green' }} onClick={Casa} id='Accesso'>
						<span className='Icon'>
							<IoEnterOutline />
						</span>
						<span className='Text'>Accesso</span>
					</LinkAttivo>
				)}
			</ul>
		</div>
	);
}
