import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import '../Component/CSS/Rapporti.css';
import { getOut } from '../App';
import { toast } from 'react-toastify';
import Select from 'react-select';
import SignatureCanvas from 'react-signature-canvas';

export default function Rapporti() {
	getOut(5);

	const level = sessionStorage.getItem('ProUser');
	const [options, setOptions] = useState(['']);
	const [userChoise, setUserChoice] = useState(['']);
	const [commessaScelta, setCommessaScelta] = useState(['']);
	const [report, setReport] = useState(['']);

	const rapportoScelto = useRef(null);
	const GiardinaggioCheck = useRef(null);
	const PulizieCheck = useRef(null);
	const FacchinaggioCheck = useRef(null);
	const DerattizzazioneCheck = useRef(null);
	const SmaltimentoCheck = useRef(null);

	const refCanvas = useRef();

	const Cantieri = [
		{ value: 'Cam. Comm Pesaro', label: 'Cam. Comm Pesaro' },
		{ value: 'CCIA Pesaro', label: 'CCIA Pesaro' },
		{ value: 'Comm. Tribut. Marche', label: 'Comm. Tribut. Marche' },
		{ value: 'Giud. Pace Latina', label: 'Giud. Pace Latina' },
		{ value: 'Giud. Pace Pesaro', label: 'Giud. Pace Pesaro' },
		{ value: 'IDER', label: 'IDER' },
		{ value: 'Mot. Civ. Pesaro', label: 'Mot. Civ. Pesaro' },
		{ value: 'Pref. Pesaro', label: 'Pref. Pesaro' },
		{ value: 'Proc. Latina', label: 'Proc. Latina' },
		{ value: 'Rag. Pesaro', label: 'Rag. Pesaro' },
		{ value: 'Rag. Terr. Stato Ancona', label: 'Rag. Terr. Stato Ancona' },
		{ value: 'Roma', label: 'Roma' },
		{ value: 'Trib. Frosinone', label: 'Trib. Frosinone' },
		{ value: 'Trib. Latina', label: 'Trib. Latina' },
		{ value: 'Trib. Latina Sez. Fallimentare', label: 'Trib. Latina Sez. Fallimentare' },
		{ value: 'Trib. Rimini', label: 'Trib. Rimini' },
		{ value: 'Trib. Urbino', label: 'Trib. Urbino' },
		{ value: 'Trib. Pesaro', label: 'Trib. Pesaro' },
		{ value: 'Traser', label: 'Traser' },
		{ value: 'Uni. Urbino', label: 'Uni. Urbino' },
		{ value: 'Ferie', label: 'Ferie' },
		{ value: 'Permesso', label: 'Permesso' },
		{ value: 'Malattia', label: 'Malattia' },
		{ value: 'Infortunio', label: 'Infortunio' },
		{ value: 'Aspettativa', label: 'Aspettativa' },
		{ value: '104', label: '104' },
		{ value: 'Sospensione', label: 'Sospensione' },
	];

	const MailInviata = async function (corpo) {
		try {
			await axios.post(`${process.env.REACT_APP_URL_SERVER}/InviaMail`, corpo);
			toast.success('Mail di Notifica Inviata con Successo.');
		} catch (error) {
			console.log(error);
			toast.error(`Mail di Notifica NON inviata, contattare l'Amministrazione.`);
		}
	};

	const Reset = () => {
		let form = document.getElementById('SubmitRapporto');
		form.reset();
		refCanvas.current.clear();
		setUserChoice(['']);
		setCommessaScelta(['']);

		rapportoScelto.current.value = '';
		document.getElementById(`VecchiaFirma`).src = `${process.env.REACT_APP_URL_BASE}/Logo.png`;
		document.getElementById('RapportiIntervento').value = '';
		document.getElementsByClassName('css-1dimb5e-singleValue')[0].innerText = 'Scelta Rapporto di Intervento';

		document.getElementById('AggiungiRapporto').style.display = 'block';
		document.getElementById('ModificaRapporto').style.display = 'none';
	};

	const getUser = async () => {
		const arr = [];
		await axios.get(`${process.env.REACT_APP_URL_SERVER}/DipendentiAttivi`).then((res) => {
			let result = res.data;
			result.map((data) => {
				return arr.push({ value: data.Dipendente, label: data.Dipendente });
			});
			setOptions(arr);
		});
	};

	const getReport = async () => {
		const arr = [{ value: '', label: '' }];
		await axios.get(`${process.env.REACT_APP_URL_SERVER}/Report`).then((res) => {
			let result = res.data;
			result.map((data) => {
				return arr.push({ value: data.ID, label: data.Titolo });
			});
			setReport(arr);
		});
	};

	const ImpostaValoreSelect = (valore) => {
		const option = Cantieri.find((option) => option.value === valore);
		setCommessaScelta(option);
	};

	const SelezioneIncaricati = (choice) => {
		setUserChoice(choice);
	};

	const SelezionaCommessa = (choice) => {
		setCommessaScelta(choice);
	};

	const SelezioneRapporto = async (scelta) => {
		await axios.post(`${process.env.REACT_APP_URL_SERVER}/MostraReport/${scelta.value}`).then((res) => {
			let result = res.data;
			rapportoScelto.current.value = scelta.value;
			document.getElementById('AggiungiRapporto').style.display = 'none';
			for (const key in result[0]) {
				if (key === 'Commessa') {
					ImpostaValoreSelect(`${result[0][key]}`);
				} else if (key === 'Descrizione') {
					document.getElementById(`Descrizione`).value = result[0][key];
				} else if (key === 'Macchinari') {
					document.getElementById(`Macchinari`).value = result[0][key];
				} else if (key === 'Materiali') {
					document.getElementById(`Materiali`).value = result[0][key];
				} else if (key === 'DPI') {
					document.getElementById(`DPI`).value = result[0][key];
				} else if (key === 'Incaricati') {
					const incaricati = JSON.parse(result[0][key]);
					SelezioneIncaricati(incaricati);
				} else if (key === 'Giardinaggio') {
					result[0][key] === 'on' ? (GiardinaggioCheck.current.checked = true) : (GiardinaggioCheck.current.checked = false);
				} else if (key === 'Pulizie') {
					result[0][key] === 'on' ? (PulizieCheck.current.checked = true) : (PulizieCheck.current.checked = false);
				} else if (key === 'Facchinaggio') {
					result[0][key] === 'on' ? (FacchinaggioCheck.current.checked = true) : (FacchinaggioCheck.current.checked = false);
				} else if (key === 'Derattizzazione') {
					result[0][key] === 'on' ? (DerattizzazioneCheck.current.checked = true) : (DerattizzazioneCheck.current.checked = false);
				} else if (key === 'Smaltimento') {
					result[0][key] === 'on' ? (SmaltimentoCheck.current.checked = true) : (SmaltimentoCheck.current.checked = false);
				} else if (key === 'DocumentoRif') {
					document.getElementById(`Documento`).value = result[0][key];
				} else if (key === 'NoteStraordinario') {
					document.getElementById(`TestoStraordinario`).value = result[0][key];
				} else if (key === 'NoteAltro') {
					document.getElementById(`TestoAltro`).value = result[0][key];
				} else if (key === 'DataRichiesta') {
					document.getElementById(`DataRichiesta`).value = result[0][key];
				} else if (key === 'DataProgrammata') {
					document.getElementById(`Programmata`).value = result[0][key];
				} else if (key === 'DataInizio') {
					document.getElementById(`DataInizio`).value = result[0][key];
				} else if (key === 'DataFine') {
					document.getElementById(`DataFine`).value = result[0][key];
				} else if (key === 'DataControllo') {
					document.getElementById(`Data`).value = result[0][key];
				} else if (key === 'Firmatario') {
					document.getElementById(`Firmatario`).value = result[0][key];
				} else if (key === 'Firma') {
					document.getElementById(`VecchiaFirma`).src = `${process.env.REACT_APP_URL_BASE}/FirmaRapportoIntervento/${result[0][key]}`;
				}
			}
			document.getElementById('ModificaRapporto').style.display = 'block';
		});
	};

	const CaricaIntervento = async () => {
		let form = document.getElementById('SubmitRapporto');
		let data = new FormData(form);
		const PacchettoDati = {};
		let CorpoMail = '';

		data.append('Incaricati', JSON.stringify(userChoise));

		for (let entry of data) {
			PacchettoDati[entry.at(0)] = entry.at(1);
			if (entry.at(0) === 'Incaricati') {
				const value = userChoise.map((item) => item.value).join(', ');
				CorpoMail += `<strong>${entry.at(0)}:</strong> ${value}
                <br />`;
			} else {
				CorpoMail += `<strong>${entry.at(0)}:</strong> ${entry.at(1)} 
                <br />`;
			}

			if (!entry.at(1)) {
				if (entry.at(0) === 'NoteStraordinario') {
					continue;
				} else if (entry.at(0) === 'NoteAltro') {
					continue;
				} else if (entry.at(0) === 'DocumentoRif') {
					toast.warn(`Se non è previsto lo smaltimento inserire per codice 00000`);
					return;
				} else {
					toast.warn(`Devi compilare tutti i campi, non hai inserito ${entry.at(0)}`);
					return;
				}
			}
		}
		PacchettoDati['Titolo'] = `${PacchettoDati['DataControllo']} - ${PacchettoDati['Commessa']}`;
		PacchettoDati['Controllo'] = sessionStorage.getItem('Nome');
		PacchettoDati['Firma'] = `${PacchettoDati['DataControllo']}-${PacchettoDati['Commessa']}.png`;

		const canvas = refCanvas.current.toDataURL();

		CorpoMail = `<strong>Titolo Intervento:</strong> ${PacchettoDati['Titolo']} 
        <br /> 
        <strong>Operatore compilazione:</strong> ${PacchettoDati['Controllo']}
        <br /> 
        ${CorpoMail}
        <br />
        <strong>Firma:</strong>
        <br />
        ${process.env.REACT_APP_URL_BASE}/FirmaRapportoIntervento/${PacchettoDati['Firma']}`;

		const Mail = {
			Corpo: CorpoMail,
			Oggetto: `Rapporto di Intervento ${PacchettoDati['Titolo']}`,
			Mail: process.env.REACT_APP_MAIL_RAPPORTI,
			Testo: CorpoMail,
		};

		const Mail2 = {
			Corpo: CorpoMail,
			Oggetto: `Rapporto di Intervento ${PacchettoDati['Titolo']}`,
			Mail: 'roberto.binetti@pultra.it',
			Testo: CorpoMail,
		};

		await MailInviata(Mail2);
		await MailInviata(Mail);

		await axios.post(`${process.env.REACT_APP_URL_SERVER}/SalvaFirmaRapporto`, {
			NomeFile: PacchettoDati['Firma'],
			Canvas: canvas,
		});
		await axios.post(`${process.env.REACT_APP_URL_SERVER}/PostRapporto`, PacchettoDati);

		toast.success('Inserito');
		getReport();

		Reset();
	};

	const ModificaIntervento = async () => {
		let form = document.getElementById('SubmitRapporto');
		let data = new FormData(form);
		const PacchettoDati = {};

		data.append('Incaricati', JSON.stringify(userChoise));
		data.append('ID', rapportoScelto.current.value);

		for (let entry of data) {
			PacchettoDati[entry.at(0)] = entry.at(1);
			if (!entry.at(1)) {
				if (entry.at(0) === 'NoteStraordinario') {
					continue;
				} else if (entry.at(0) === 'NoteAltro') {
					continue;
				} else if (entry.at(0) === 'DocumentoRif') {
					toast.warn(`Se non è previsto lo smaltimento inserire per codice 00000`);
					return;
				} else {
					toast.warn(`Devi compilare tutti i campi, non hai inserito ${entry.at(0)}`);
					return;
				}
			}
		}
		PacchettoDati['Titolo'] = `${PacchettoDati['DataControllo']} - ${PacchettoDati['Commessa']}`;
		PacchettoDati['Controllo'] = sessionStorage.getItem('Nome');
		PacchettoDati['Firma'] = `${PacchettoDati['DataControllo']}-${PacchettoDati['Commessa']}.png`;

		const canvas = refCanvas.current.toDataURL();

		await axios.post(`${process.env.REACT_APP_URL_SERVER}/SalvaFirmaRapporto`, {
			NomeFile: PacchettoDati['Firma'],
			Canvas: canvas,
		});
		await axios.put(`${process.env.REACT_APP_URL_SERVER}/ModificaRapporto`, PacchettoDati);

		toast.success('Modificato');
		getReport();

		Reset();
	};

	useEffect(() => {
		getUser();
		getReport();
	}, []);

	return (
		<div id='Contenuto'>
			<div id='Rapporti'>
				{process.env.REACT_APP_TIPOSITO === 'SVILUPPO' ? (
					<h1 style={{ color: 'red', textDecoration: 'underline' }}>SVILUPPO</h1>
				) : (
					<h1>Rapporto Di Intervento</h1>
				)}

				<div>
					<form name='SubmitRapporto' id='SubmitRapporto'>
						{level >= 10 && (
							<>
								<span
									className='Centrato'
									style={{
										marginBottom: '50px',
										border: '2px dotted indigo',
										padding: '30px',
									}}
								>
									<p className='ComeLabel'>Consulta/Modifica Rapporto di Intervento</p>
									<Select
										options={report}
										id='RapportiIntervento'
										placeholder='Scelta Rapporto di Intervento'
										onChange={SelezioneRapporto}
										ref={rapportoScelto}
									/>
								</span>
								<button type='button' className='Centrato' onClick={Reset} style={{ width: '100%' }}>
									Crea Nuovo Rapporto
								</button>
							</>
						)}
						<span className='Centrato'>
							<p className='ComeLabel'>Commessa</p>
							<Select
								options={Cantieri}
								id='Commessa'
								name='Commessa'
								placeholder='Scelta Commessa'
								value={commessaScelta}
								onChange={SelezionaCommessa}
							/>
						</span>
						<span style={{ flex: '1', boxSizing: 'border-box' }} hidden>
							<label htmlFor='Referente'>Richiesto Da</label>
							<input type='text' placeholder='Referente' name='Richiesta' id='Referente' value={'Referente'} readOnly />
						</span>
						<span className='Centrato'>
							<label htmlFor='Descrizione'>Descrizione Intervento</label>
							<textarea name='Descrizione' id='Descrizione' rows={5} className='Centrato'></textarea>
						</span>
						<span>
							<label htmlFor='DataRichiesta'>Data Richiesta</label>
							<input type='date' name='DataRichiesta' id='DataRichiesta' />
						</span>

						<span>
							<label htmlFor='Facchinaggio'>Facchinaggio</label>
							<input name='Facchinaggio' id='Facchinaggio' type='checkbox' ref={FacchinaggioCheck} />
						</span>
						<span>
							<label htmlFor='Giardinaggio'>Giardinaggio</label>
							<input name='Giardinaggio' id='Giardinaggio' type='checkbox' ref={GiardinaggioCheck} />
						</span>
						<span>
							<label htmlFor='Smaltimento'>Smaltimento</label>
							<input name='Smaltimento' id='Smaltimento' type='checkbox' ref={SmaltimentoCheck} />
						</span>
						<span>
							<label htmlFor='Pulizie'>Pulizie</label>
							<input name='Pulizie' id='Pulizie' type='checkbox' ref={PulizieCheck} />
						</span>
						<span>
							<label htmlFor='Derattizzazione'>Derattizzazione</label>
							<input name='Derattizzazione' id='Derattizzazione' type='checkbox' ref={DerattizzazioneCheck} />
						</span>

						<span className='Centrato'>
							<label htmlFor='Documento'>Trasporto Rifiuti Alla Discarica Autorizzata</label>
							<input type='text' placeholder='Documento di Riferimento' name='DocumentoRif' id='Documento' />
						</span>
						<span className='Centrato'>
							<h2>Asset Utilizzati</h2>
						</span>
						<span>
							<label htmlFor='Macchinari'>Macchinari/Attrezzature</label>
							<textarea rows={5} name='Macchinari' id='Macchinari' />
						</span>
						<span>
							<label htmlFor='Materiali'>Materiali</label>
							<textarea rows={5} name='Materiali' id='Materiali' />
						</span>
						<span>
							<label htmlFor='DPI'>DPI</label>
							<textarea rows={5} name='DPI' id='DPI' />
						</span>
						<span className='Centrato'>
							<p className='ComeLabel'>Incaricati</p>
							<Select
								closeMenuOnSelect={false}
								isMulti
								options={options}
								value={userChoise}
								id='Incaricati'
								placeholder='Incaricati (Multiplo)'
								onChange={SelezioneIncaricati}
							/>
						</span>
						<span>
							<label htmlFor='Programmata'>Data Programmata</label>
							<input type='date' name='DataProgrammata' id='Programmata' />
						</span>
						<span>
							<label htmlFor='DataInizio'>Data Inizio Lavori</label>
							<input type='date' name='DataInizio' id='DataInizio' />
						</span>
						<span>
							<label htmlFor='DataFine'>Data Fine Lavori</label>
							<input type='date' name='DataFine' id='DataFine' />
						</span>

						{/* <span className='Centrato'>
                            <label htmlFor='Controllo'>Controllo di Corretta Esecuzione</label>
                            <input type='text' placeholder='Controllo di Corretta Esecuzione' name='Controllo' id='Controllo' />
                        </span> */}
						<span className='Centrato'>
							<label htmlFor='Data'>Data Compilazione</label>
							<input type='date' name='DataControllo' id='Data' />
						</span>
						<span className='Centrato'>
							<h2>Note</h2>
						</span>
						<span>
							<label htmlFor='TestoStraordinario'>Intervento Straordinario</label>
							<textarea rows={5} name='NoteStraordinario' className='Centrato' id='TestoStraordinario' />
						</span>
						<span style={{ marginBottom: '50px' }}>
							<label htmlFor='TestoAltro'>Altro</label>
							<textarea rows={5} name='NoteAltro' className='Centrato' id='TestoAltro' />
						</span>

						<span className='Centrato'>
							<label htmlFor='Firmatario'>Firma Committente (Nuova)</label>
							<input type='text' name='Firmatario' id='Firmatario' placeholder='Nome Firmatario' />
						</span>

						<div className='Centrato'>
							<h3>Firma (Già Effettuata)</h3>
							<img
								src={`${process.env.REACT_APP_URL_BASE}/Logo.png`}
								alt='VecchiaFirma'
								id='VecchiaFirma'
								style={{ marginTop: '25px', marginBottom: '25px' }}
							/>
						</div>
						<span className='Centrato' style={{ width: '100%', minWidth: '100%' }}>
							<p className='ComeLabel'>Firma Per Presa Visione</p>
							<SignatureCanvas penColor='black' canvasProps={{ className: 'sigCanvas' }} ref={refCanvas} id='Firma' />
						</span>

						<button
							type='button'
							className='Centrato'
							id='AggiungiRapporto'
							onClick={(e) => {
								e.preventDefault();
								CaricaIntervento();
							}}
						>
							Invia Rapporto di Intervento
						</button>

						<button
							type='button'
							style={{ display: 'none', background: 'orange' }}
							className='Centrato'
							id='ModificaRapporto'
							onClick={(e) => {
								e.preventDefault();
								ModificaIntervento();
							}}
						>
							Modifica Rapporto di Intervento
						</button>
						<span style={{ marginTop: '50px' }}>
							<button className='Centrato' style={{ background: 'crimson' }} type='button' onClick={() => refCanvas.current.clear()}>
								Cancella Firma
							</button>
						</span>
					</form>
				</div>
			</div>
		</div>
	);
}
