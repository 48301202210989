import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import '../Component/CSS/Comunicazioni.css';

export default function ComunicazioniAnonime() {
	const mail = useRef();
	const nominativo = useRef();
	const corpo = useRef();

	const InviaDenuncia = async () => {
		if (corpo.current.value) {
			await axios.post(`${process.env.REACT_APP_URL_SERVER}/InviaMail`, {
				Mail: process.env.REACT_APP_MAIL_DENUNCE,
				Oggetto: 'Comunicazione esterna',
				Testo: `<strong>${nominativo.current.value}</strong> <br /><strong>Mail (se indicata):</strong> ${mail.current.value}<br /><br /> </strong>Ha scritto: </strong> <br />${corpo.current.value}`,
			});
			toast.success('Comunicazione Inviata.');
		} else {
			toast.error('Il corpo della comunicazione è vuoto, comunicazione NON INVIATA.');
		}
	};

	return (
		<div id='Contenuto'>
			<h1>Whistleblowing (segnalazione illeciti)</h1>
			<div id='Whistleblowing'>
				<div>
					<form>
						<strong style={{ fontSize: '24px', marginBottom: '50px' }}>
							Questa pagina è dedicata solamente alla segnalazione degli illeciti, per comunicare con l'azienda utilizzate l'apposita
							sezione "Comunicazioni Interne" dopo aver effettuato il login, per contatti commerciali utilizzare l'apposita sezione
							"Contatti Commerciali" presente in HomePage
							<br />
						</strong>
						<label htmlFor='TestoComunicazione'>Segnalazione Illecito</label>
						<textarea
							id='TestoComunicazione'
							name='TestoComunicazione'
							rows='10'
							cols='50'
							placeholder={`
                            
                            
                            
Inserisci qui la segnalazione anonima dell'illecito per la Pultra`}
							ref={corpo}
						></textarea>
						<p style={{ fontSize: '24px' }}>
							<br />
							Le comunicazioni inviate tramite questa pagina sono per i mesaggi degli utenti interni all'azienda per la segnalazione di
							illeciti (Whistleblowing). Tutte le segnalazioni inviate da questa pagina sono in forma anonima (utilizzare i campi
							Nominativo e eMail è facoltativo). Le denunce effettuate con questo canale saranno sottoposte al responsabile designato e
							manterrà l'anonimato del segnalatore, anche nel caso in cui venga indicato nome e/o mail.
							<br />
						</p>
						<label htmlFor='Nominativo'>Nominativo</label>
						<input type='text' name='Nominativo' placeholder='Nome (Opzionale)' ref={nominativo} id='Nominativo' />

						<label htmlFor='eMail'>eMail</label>
						<input type='text' name='eMail' placeholder='eMail (Opzionale)' ref={mail} autoComplete='on' id='eMail' />

						<button type='button' onClick={InviaDenuncia}>
							Invia la Segnalazione
						</button>
					</form>
					<hr></hr>
				</div>
			</div>
		</div>
	);
}
