import React from 'react';
import '../Component/CSS/Certificazioni.css';

export default function Certificazioni() {
	return (
		<div id='Contenuto'>
			<h1>Documenti</h1>
			<div id='Cert' style={{ display: 'flex' }}>
				<div>
					<h3>Certificazioni</h3>
					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/9001.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = '9001';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							ISO 9001
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/14001.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = '14001';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							ISO 14001
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/45001.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = '45001';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							ISO 45001
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/13549.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = '13549';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							UNI EN 13549
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/SA8000.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'SA8000';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							Certificato SA 8000
						</button>
					</div>
				</div>

				<div>
					<h3>Documentazione</h3>
					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/PoliticaAziendale.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'Politica Aziendale';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							Politica Aziendale
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/CodiceEtico.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'Codice Etico';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							Codice Etico
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/CodiceDisciplinare.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'Codice Disciplinare';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							Codice Disciplinare
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/BilancioSociale.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'Bilancio Sociale';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							Bilancio Sociale <br /> Aprile 2024
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/RiesameDirezione.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'Riesame Direzione';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							Riesame Direzione <br /> Ottobre 2023
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/report sodd.2023.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'Report SODD 2023';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							Report SODD 2023
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/DichiarazioneAmbientale.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'Dichiarazione Ambientale';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							Dichiarazione Ambientale
						</button>
					</div>

					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/DocSA8000.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'Dichiarazione Ambientale';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							Doc SA 8000
						</button>
					</div>

					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/PariOpportunita.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'Informativa Pari Opportunità';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							Informativa Pari Opportunità
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/CongedoParentale.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'Informativa Congedo Parentale';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							Informativa Congedo Parentale
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								const urlDummy = `${process.env.REACT_APP_URL_BASE}/DipendentiDocumentazioneMaterialeVarie/Certificazioni/ModuloSegnalazioni.pdf`;
								document.getElementById('MostraFile').setAttribute('data', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').setAttribute('href', urlDummy);
								document.getElementById('ScaricaSeNonLoVedi').textContent = 'Modulo Segnalazioni';
								document.getElementById('MostraFile').style.display = 'flex';
							}}
						>
							Modulo Segnalazioni
						</button>
					</div>
				</div>
				<hr></hr>
				<div id='Displayer'>
					<object id='MostraFile' data='' type='application/pdf' style={{ marginTop: '25px' }} hidden>
						<p style={{ fontStyle: 'italic', fontSize: 'medium', lineHeight: '2.5em', margin: '0px', padding: '0px' }}>
							Se stai leggendo questa frase non puoi visualizzare il File ma devi scaricarlo dal seguente link: <br />
							<a id='ScaricaSeNonLoVedi' href='./' target='_blank' download>
								SCARICA IL FILE
							</a>
						</p>
					</object>
				</div>
				<div id='Noter'>
					<p
						id='SpecificheCellulare'
						style={{ fontStyle: 'italic', fontSize: 'medium', lineHeight: '2.5em', margin: '0px', padding: '0px' }}
					>
						Se usi Safari o sei su un iPhone/iPad visualizzerai solamente la prima pagina del documento, per visualizzarlo integralmente
						utilizza un PC o un tablet/telefono Android
						<br />
					</p>
				</div>
			</div>
		</div>
	);
}
