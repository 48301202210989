import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import '../Component/CSS/Comunicazioni.css';

export default function ComunicazioniAnonime() {
	const mail = useRef();
	const nominativo = useRef();
	const corpo = useRef();

	const InviaComunicazione = async () => {
		if (corpo.current.value) {
			await axios.post(`${process.env.REACT_APP_URL_SERVER}/InviaMail`, {
				Mail: process.env.REACT_APP_MAIL_COMUNICAZIONI,
				Oggetto: 'Comunicazione esterna',
				Testo: `<strong>${nominativo.current.value}</strong> <br /><strong>Mail (se indicata):</strong> ${mail.current.value}<br /><br /> </strong>Ha scritto: </strong> <br />${corpo.current.value}`,
			});
			toast.success('Comunicazione Inviata.');
		} else {
			toast.error('Il corpo della comunicazione è vuoto, comunicazione NON INVIATA.');
		}
	};

	return (
		<div id='Contenuto'>
			<h1>Richieste Esterne</h1>
			<div id='ComunicazioniAnonime'>
				<div>
					<form>
						<label htmlFor='TestoComunicazione'>Richieste Esterne</label>

						<textarea
							id='TestoComunicazione'
							name='TestoComunicazione'
							rows='10'
							cols='50'
							placeholder={`
                            
                            
                            
Inserisci qui la richiesta per la Pultra`}
							ref={corpo}
						></textarea>

						<label htmlFor='Nominativo'>Nominativo</label>
						<input type='text' name='Nominativo' placeholder='Nome (Opzionale)' ref={nominativo} id='Nominativo' />

						<label htmlFor='eMail'>eMail</label>
						<input type='text' name='eMail' placeholder='eMail (Opzionale)' ref={mail} autoComplete='on' id='eMail' />

						<button type='button' onClick={InviaComunicazione}>
							Invia Richiesta
						</button>
					</form>
					<hr></hr>
				</div>
			</div>
		</div>
	);
}
